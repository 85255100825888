import React from "react"
import { Link } from "gatsby"

let Covid = (props) => (
	<div>
		{props.course == "jsinperson" ? (
			<>
				<h2 className="highlighter" style={{ display: "inline-block" }}>
					COVID-19 NOTE
				</h2>

				<p>
					Following the current safety measures with reduced size of
					the groups the registration is open for the following dates
					for the{" "}
					<span className="highlighter">in-person training</span>.
				</p>
			</>
		) : props.course == "jsonline" ? (
			<>
				<h2 className="highlighter">COVID-19 NOTE</h2>
				<p>
					The JavaScript Full-Stack Online Bootcamp is going on as
					usual, since 2017 it's always been 100% remote so in a sense
					it was born ready for times like this.{" "}
				</p>
			</>
		) : null}
	</div>
)

export default Covid
